<template>
  <div>
    <div class="columns is-marginless is-multiline">
      <div class="column is-12 is-4-desktop is-3-widescreen">
        <div
          class="content has-text-grey has-padding-x-50 has-margin-bottom-50"
        >
          <h2>Site Settings</h2>

          <p>
            Update your site settings. Changing the software enables
            software-specific features and tasks. Additional team members can be
            managed with the delegates function.
          </p>
        </div>
      </div>

      <div class="column is-12 is-8-desktop is-9-widescreen">
        <div class="columns is-multiline">
          <div class="column is-12">
            <site-details :site-id="siteId" />
          </div>

          <div class="column is-12">
            <site-delegates :site-id="siteId" />
          </div>

          <div v-if="isOwnerOrAgent" class="column is-12">
            <site-tokens type="site" :site-id="siteId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SiteSettings",
  components: {
    "site-details": () => import("./details"),
    "site-delegates": () => import("./delegates"),
    "site-tokens": () => import("@shared/sites/_siteTokens.vue")
  },
  props: {
    siteId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: true,
      processing: false
    };
  },
  computed: {
    isOwnerOrAgent() {
      return this.$store.getters["user/isAgent"]();
      // Uncomment this code when making public
      // return (
      //   this.$store.getters["user/isAgent"]() ||
      //   this.$store.getters["sites/site/userRole"](this.siteId) === "owner"
      // );
    },
    site() {
      return this.$store.getters["sites/site"](this.siteId) || {};
    }
  },
  created() {
    this.$store.dispatch("sites/observeSite", {
      siteId: this.siteId
    });
  },
  beforeDestroy() {
    this.$store.dispatch("sites/unobserveSite", {
      siteId: this.siteId
    });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (
        vm.$store.getters["sites/site/userRole"](to.params.siteId) ===
          "delegate" &&
        !vm.$store.getters["user/isAdmin"]()
      ) {
        next({ path: `/sites/${to.params.siteId}` });
        vm.$toast.open({
          message: "You don't have permission to access this content",
          type: "is-danger",
          position: "is-bottom",
          queue: false
        });
      }
    });
  }
};
</script>
